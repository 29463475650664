input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #171717 inset !important;
    -webkit-text-fill-color: white;
}
* {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hJsDPI {
    /* width: 100%; */
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(6px);
    border-radius: 8px;
    max-height: 150px;
    z-index: 10;
    margin-bottom: 0px;
    cursor: pointer;
    height: 120px;
    position: relative;
}
.hJsDPI_max {
    width: 40px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(6px);
    border-radius: 8px;
    max-height: 40px;
    z-index: 10;
    margin-bottom: 0px;
    cursor: pointer;
    height: 40px;
    position: relative;
}
